import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "./Promotion.css"

import React, {useState, useMemo, useCallback, useLayoutEffect, useEffect, Suspense} from 'react';

import { toast } from 'react-toastify';

import {DateSelectArg, EventChangeArg} from '@fullcalendar/react'; // must go before plugins
import { dateOnly, formatToYMD } from '../../../helpers/dateHelper';
import { PromotionCriteria } from '../../../backoffice/dtos/product/promotionCriteria';
import {
    useAddPromotionMutation, useAlterPromotionProductMutation,
    useClonePromotionMutation
} from '../../../backoffice/apis/promotionAPI';
import { useGetCustomersQuery } from '../../../backoffice/apis/customerAPI';
import { Promotion } from '../../../backoffice/dtos/product/promotion';
import {useGetMediaTypesQuery} from "../../../backoffice/apis/mediaApi";
import CriteriaModal from './CriteriaModal';
import PromotionsTimeline from "./PromotionsTimeline";
import {TimelineEventClick} from '../../../app/types';
import {useGetTimelineResourcesQuery} from "../../../backoffice/apis/TimelineAPI";
import {ResourceItem, TimelineMasterWithEvent, toResourceItems, toTimelineEvents} from "../models/NTimelineModel";
import { PromotionPartialChange, getDefaultTimelineRangeStr, getUserReadableDateRange, rangeParse } from '../../../helpers/Types';
import {Modal, ModalBody, ModalHeader, Spinner} from 'reactstrap';
import StatisticsBody from "../../statistics/StatisticsBody";
import { useSetPromotionId } from '../../../app/hooks';
import { useTranslation } from 'react-i18next';
import { useGetMonthsHolidaysQuery } from '../../../backoffice/apis/holidaysAPI';
import { MediaType } from '../../../backoffice/dtos/media/MediaType';
import { Customer } from '../../../backoffice/dtos/customer/customer';
import { RouteProps} from 'react-router-dom';
import { Loading } from '../../views/Loading';
import { PromotionColorInfo } from './PromotionColorInfo';

interface PromotionsTimelineBaseArgs extends React.HTMLAttributes<RouteProps> {
}

export const TimelineDefaultPromotionCriteria: Readonly<PromotionCriteria> = {
    customerIds: undefined, approvalStatus: undefined, mediaTypeIds: undefined, dateRange: getDefaultTimelineRangeStr()
}
export default function PromotionsTimelineBase(props: PromotionsTimelineBaseArgs) {
    const [promotionCriteria, setPromotionCriteria] = useState<PromotionCriteria>(TimelineDefaultPromotionCriteria)

    const {t} = useTranslation(['promotion_timeline','promotion','common'])
    //
    // RTK Query api
    //
    const { data: customers, isFetching: areCustomersLoading } = useGetCustomersQuery({ query:"", includeDeleted:false });
    const { data: mediaTypes, isFetching: areMediatypesLoading } = useGetMediaTypesQuery();
    const { data: holidays } = useGetMonthsHolidaysQuery(promotionCriteria.dateRange!);
    const { data: timelineResources, isFetching } = useGetTimelineResourcesQuery(promotionCriteria, { 
        refetchOnMountOrArgChange: true, 
        skip: (areCustomersLoading || areMediatypesLoading)
     });

    const [addPromotion] = useAddPromotionMutation()
    const [clonePromotion] = useClonePromotionMutation()
    const [alterPromotion] = useAlterPromotionProductMutation()

    const setPromotionId = useSetPromotionId()

    const [ isColorInfoOpen, setColorInfoOpen ] = useState(false)

    const toggleColorInfo = useCallback(() => setColorInfoOpen(ref => !ref), [setColorInfoOpen]);
    
    const [ focusedDate, setFocusedDate ] = useState<Date>()

    //
    // End of RTK Query api 
    //
    const eventDateRangeChange = useCallback(async (e: EventChangeArg) => {
        const masterWithEvent = e.event.extendedProps as TimelineMasterWithEvent;
        const targetResource = e.event.getResources()[0]._resource.extendedProps

        if (!targetResource)
            return;

        const [newStart, newEnd] = [e.event._instance!.range.start, e.event._instance!.range.end]

        let dateRange = null;
        if (newStart && newEnd){
            newEnd.setDate(newEnd.getDate() - 1)
            dateRange = {fromDate: formatToYMD(newStart), toDate: formatToYMD(newEnd)};
        }

        let mediaTypeId = null;
        if (targetResource.mediaTypeId !== masterWithEvent.mediaType.mediaTypeId)
            mediaTypeId = targetResource.mediaTypeId ?? null;

        let body: PromotionPartialChange | null = null;
        if (dateRange || mediaTypeId)
            body = { mediaTypeId, dateRange }

        let focusedDate = new Date(masterWithEvent.event.fromDate);
        if (dateRange)
            focusedDate = new Date(dateRange.fromDate);

        setFocusedDate(focusedDate);

        try {
            if (targetResource.customerId !== masterWithEvent.customer.customerId || targetResource.mediaTypeId !== masterWithEvent.mediaType.mediaTypeId) {
                await clonePromotion({ id: masterWithEvent.event.id, newCustomerId: targetResource.customerId, body }).unwrap()
            } else if (body) {
                await alterPromotion({ id: masterWithEvent.event.id, body }).unwrap()
            }
        } catch(e: any) {
            toast(e);
        }
    }, [clonePromotion, alterPromotion]);

    const [showFilterModal, setShowFilterModal] = useState(false);

    const createEventForDateRange = useCallback(async (arg: DateSelectArg) => {
        const masterWithEvent = arg.resource?._resource.extendedProps as ResourceItem

        if (masterWithEvent === undefined)
            return;

        const customerId = masterWithEvent.customerId;
        const mediaTypeId = masterWithEvent.mediaTypeId;

        // end date is EXCLUSIVE
        let toDate = dateOnly(arg.end)
        toDate.setDate(toDate.getDate() - 1)

        const fromDate = dateOnly(arg.start)

        if (fromDate >= toDate)
            return

        const mediaTypeDescription = mediaTypes!.filter(m => m.id === mediaTypeId)[0].description;

        const newPromotion: Partial<Promotion> = {
            customerId: customerId,
            fromDate: formatToYMD(arg.start),
            toDate: formatToYMD(toDate),
            mediaTypeId: mediaTypeId,
            mediaTypeDescription: mediaTypeDescription,
            description: undefined
        }

        try {
            setFocusedDate(fromDate);

            const payload = await addPromotion(newPromotion).unwrap();
            setPromotionId(payload.id);
        } catch(e: any) {
            toast(t('unsuccessful_promotion_creation_text',{ns:'promotion', error: e}))
        }
    }, [addPromotion, mediaTypes])

    const promotionOpenEvent = useCallback((info: TimelineEventClick) => {
        const eventWithMaster: TimelineMasterWithEvent = info.event.extendedProps;
        const promotionId = eventWithMaster.event.id;

        setFocusedDate(new Date(eventWithMaster.event.fromDate))
        setPromotionId(promotionId)
    }, [])

    const [loading, setLoading] = useState(true)
    const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
    const [filteredMediaTypes, setFilteredMediaTypes] = useState<MediaType[]>([]);
    
    useEffect(() => {
        setLoading(true)

        if (customers !== undefined && mediaTypes !== undefined && !isFetching) {
                let filterdCustomers = customers ? [...customers] : [];
                let filteredMediaTypes = mediaTypes ? [...mediaTypes] : [];

                if (promotionCriteria.customerIds && promotionCriteria.customerIds.length > 0)
                    filterdCustomers = filterdCustomers.filter(m => promotionCriteria.customerIds!.includes(m.id));

                if (promotionCriteria.mediaTypeIds && promotionCriteria.mediaTypeIds.length > 0)
                    filteredMediaTypes = filteredMediaTypes.filter(m => promotionCriteria.mediaTypeIds!.includes(m.id));

                setFilteredCustomers(filterdCustomers)
                setFilteredMediaTypes(filteredMediaTypes)
                setLoading(false)
        }
    }, [promotionCriteria.customerIds, promotionCriteria.mediaTypeIds, customers, mediaTypes, isFetching, timelineResources])

    const resources = useMemo(() => {
        return toResourceItems(timelineResources);
    }, [filteredMediaTypes, filteredCustomers, t]);

    const timelineEvents = useMemo(() => {
            return toTimelineEvents(timelineResources);
    }, [timelineResources]);

    /*
     * END MODAL SECTION
     */
    const [showStatistics, setShowStatistics] = useState(false)
    let mainView : JSX.Element 
    if (loading) {
        mainView = (
            <Loading />
        )
    } else {
        mainView = (
                <PromotionsTimeline
                    focusedDate={focusedDate}
                    holidays={holidays}
                    promotionCriteria={promotionCriteria}
                    toggleStatistics={() => setShowStatistics(val => !val)}
                    toggleShowFilters={() => setShowFilterModal(prev => !prev)}
                    timelineEvents={timelineEvents}
                    timelineResources={resources}
                    eventClickHandler={promotionOpenEvent}
                    calendarSelectHandler={createEventForDateRange}
                    eventDateRangeChange={eventDateRangeChange}
                    setPromotionCriteria={(promotionCriteria: PromotionCriteria) => setPromotionCriteria(promotionCriteria)}
                    toggleColorInfo={toggleColorInfo}
                />
        )
    }

    return <>
            {mainView}

            <Modal isOpen={showStatistics} toggle={() => setShowStatistics(val => !val)} size='xl'>
                <ModalBody>
                    <React.Suspense fallback={<Spinner />}>
                        {showStatistics && <StatisticsBody 
                        promotionCriteria={promotionCriteria}
                        toggle={() => setShowStatistics(val => !val)}

                        setSelectedPromotionCriteria={(criteria) => {
                            setPromotionCriteria(criteria)
                        }}
                        initialFilter={TimelineDefaultPromotionCriteria}
                        />}
                    </React.Suspense>
                </ModalBody>
            </Modal>

            <CriteriaModal 
                isOpen={showFilterModal} 
                toggle={() => {
                    setShowFilterModal(prev => !prev)
                }}
                currentFilter={promotionCriteria}
                setSelectedPromotionCriteria={(criteria) => {
                    setPromotionCriteria(criteria)
                }}
                initialFilter={TimelineDefaultPromotionCriteria}
            />

            <PromotionColorInfo isOpen={isColorInfoOpen} toggle={toggleColorInfo}/>
    </>
}
