import './Statistics.css'
import { Table } from "reactstrap"
import { StatisticsEntry } from "./StatisticsEntry";
import { useTranslation } from 'react-i18next';
import { statisticsModel } from '../../backoffice/dtos/statistics/statisticsModel';


export default function StatisticsBeer(
    {
        statisticsModel
    }: {
        statisticsModel: statisticsModel
    }
) {
    const { t } = useTranslation(['statistics', 'promotion_timeline'])
    if (!statisticsModel)
        return <></>

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th className="text-align-center">Top 5</th>
                    </tr>
                </thead>
            </Table>
            <Table striped hover>
                <thead>
                    <tr>
                        <th className="text-align-center">{t('brand')}</th>
                        <th className="text-align-center">{t('beer')} (Hls)</th>
                        <th className="text-align-center">LY {t('beer')} (Hls)</th>
                        <th className="text-align-center">{t('beer')} (Hls) Diff</th>
                        <th className="text-align-center">{t('beer')} (Hls) Diff %</th>
                        <th className="text-align-center">{t('beer')} (€)</th>
                        <th className="text-align-center">LY {t('beer')} (€)</th>
                        <th className="text-align-center">{t('beer')} (€) Diff</th>
                        <th className="text-align-center">{t('beer')} (€) Diff %</th>
                    </tr>
                </thead>
                <tbody>
                    {statisticsModel.topCustomersDetails.map(m =>
                        <StatisticsEntry columns={[
                            { type: 'Plain', value: m.company },
                            { type: 'Financial', value: (m.beerHls), className: "text-align-end" },
                            { type: 'Financial', value: (m.lyBeerHls), className: "text-align-end" },
                            { type: 'Delta', value: (m.beerHlsDelta), className: "text-align-end" },
                            { type: 'Delta %', value: (m.beerHlsDeltaPer), className: "text-align-end" },
                            { type: 'Financial', value: (m.beerEuro), className: "text-align-end" },
                            { type: 'Financial', value: (m.lyBeerEuro), className: "text-align-end" },
                            { type: 'Delta', value: (m.beerEuroDelta), className: "text-align-end" },
                            { type: 'Delta %', value: (m.beerEuroDeltaPer), className: "text-align-end" }
                        ]} />)}
                    <StatisticsEntry columns={[
                        { type: 'Plain', value: t('total'), className: "strong" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.beerHls), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.lyBeerHls), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.topCustomers.beerHlsDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.topCustomers.beerHlsDeltaPer), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.beerEuro), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.lyBeerEuro), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.topCustomers.beerEuroDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.topCustomers.beerEuroDeltaPer), className: "text-align-end" }
                    ]} />
                </tbody>
            </Table>

            <br />
            <Table>
                <thead>
                    <tr>
                        <th className="text-align-center">Other</th>
                    </tr>
                </thead>
            </Table>
            <Table striped hover>
                <thead>
                    <tr>
                        <th className="text-align-center">{t('brand')}</th>
                        <th className="text-align-center">{t('beer')} (Hls)</th>
                        <th className="text-align-center">LY {t('beer')} (Hls)</th>
                        <th className="text-align-center">{t('beer')} (Hls) Diff</th>
                        <th className="text-align-center">{t('beer')} (Hls) Diff %</th>
                        <th className="text-align-center">{t('beer')} (€)</th>
                        <th className="text-align-center">LY {t('beer')} (€)</th>
                        <th className="text-align-center">{t('beer')} (€) Diff</th>
                        <th className="text-align-center">{t('beer')} (€) Diff %</th>
                    </tr>
                </thead>
                <tbody>
                    {statisticsModel.otherCustomersDetails.map(m =>
                        <StatisticsEntry columns={[
                            { type: 'Plain', value: m.company },
                            { type: 'Financial', value: (m.beerHls), className: "text-align-end" },
                            { type: 'Financial', value: (m.lyBeerHls), className: "text-align-end" },
                            { type: 'Delta', value: (m.beerHlsDelta), className: "text-align-end" },
                            { type: 'Delta %', value: (m.beerHlsDeltaPer), className: "text-align-end" },
                            { type: 'Financial', value: (m.beerEuro), className: "text-align-end" },
                            { type: 'Financial', value: (m.lyBeerEuro), className: "text-align-end" },
                            { type: 'Delta', value: (m.beerEuroDelta), className: "text-align-end" },
                            { type: 'Delta %', value: (m.beerEuroDeltaPer), className: "text-align-end" }
                        ]} />)}
                    <StatisticsEntry columns={[
                        { type: 'Plain', value: t('total'), className: "strong" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.beerHls), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.lyBeerHls), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.otherCustomers.beerHlsDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.otherCustomers.beerHlsDeltaPer), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.beerEuro), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.lyBeerEuro), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.otherCustomers.beerEuroDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.otherCustomers.beerEuroDeltaPer), className: "text-align-end" }
                    ]} />
                </tbody>
            </Table>
            <br />
            <Table>
                <thead>
                    <tr>
                        <th className="text-align-center">Totals</th>
                    </tr>
                </thead>
            </Table>
            <Table striped hover>
                <thead>
                    <tr>
                        <th className="text-align-center">{t('brand')}</th>
                        <th className="text-align-center">{t('beer')} (Hls)</th>
                        <th className="text-align-center">LY {t('beer')} (Hls)</th>
                        <th className="text-align-center">{t('beer')} (Hls) Diff</th>
                        <th className="text-align-center">{t('beer')} (Hls) Diff %</th>
                        <th className="text-align-center">{t('beer')} (€)</th>
                        <th className="text-align-center">LY {t('beer')} (€)</th>
                        <th className="text-align-center">{t('beer')} (€) Diff</th>
                        <th className="text-align-center">{t('beer')} (€) Diff %</th>
                    </tr>
                </thead>
                <tbody>
                    <StatisticsEntry columns={[
                        { type: 'Plain', value: `TOP 5 ${t('total')}`, className: "strong" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.beerHls), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.lyBeerHls), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.topCustomers.beerHlsDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.topCustomers.beerHlsDeltaPer), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.beerEuro), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.lyBeerEuro), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.topCustomers.beerEuroDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.topCustomers.beerEuroDeltaPer), className: "text-align-end" }
                    ]} />
                    <StatisticsEntry columns={[
                        { type: 'Plain', value: `Other ${t('total')}`, className: "strong" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.beerHls), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.lyBeerHls), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.otherCustomers.beerHlsDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.otherCustomers.beerHlsDeltaPer), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.beerEuro), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.lyBeerEuro), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.otherCustomers.beerEuroDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.otherCustomers.beerEuroDeltaPer), className: "text-align-end" }
                    ]} />
                    <StatisticsEntry columns={[
                        { type: 'Plain', value: t('total'), className: "strong" },
                        { type: 'Financial', value: (statisticsModel.allCustomers.beerHls), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.allCustomers.lyBeerHls), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.allCustomers.beerHlsDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.allCustomers.beerHlsDeltaPer), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.allCustomers.beerEuro), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.allCustomers.lyBeerEuro), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.allCustomers.beerEuroDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.allCustomers.beerEuroDeltaPer), className: "text-align-end" }
                    ]} />
                </tbody>
            </Table>
        </>
    )
}
