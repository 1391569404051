import './Statistics.css'
import { Button, ButtonGroup, Col, Dropdown, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table } from "reactstrap"
import { useGetStatisticsQuery } from "../../backoffice/apis/statisticsAPI";
import { StatisticsEntry } from "./StatisticsEntry";
import { useTranslation } from 'react-i18next';
import { PromotionCriteria } from '../../backoffice/dtos/product/promotionCriteria';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserReadableDateRange, rangeParse } from '../../helpers/Types';
import { faCopy, faFileExcel, faFilter, faX } from '@fortawesome/free-solid-svg-icons';
import CriteriaModal from '../promotions/views/CriteriaModal';
import { useState } from 'react';
import StatisticsBeer from './StatisticsBeer';
import StatisticsCider from './StatisticsCider';
import { downloadStatisticsExcel } from '../../backoffice/apis/excelAPI';


export default function StatisticsBody(
    {
        initialFilter,
        setSelectedPromotionCriteria,
        promotionCriteria,
        toggle
    }: {
        initialFilter: PromotionCriteria,
        setSelectedPromotionCriteria: (criteria: PromotionCriteria) => void
        promotionCriteria: PromotionCriteria,
        toggle: () => void
    }
) {
    const [criteriaToggle, setCriteriaToggle] = useState<boolean>(false);
    const { data: statisticsModel, isLoading: areStatisticsLoading } = useGetStatisticsQuery(promotionCriteria, { refetchOnMountOrArgChange: true })
    const [activeTab, setActiveTab] = useState('1');

    const { t } = useTranslation(['statistics', 'promotion_timeline'])
    if (!statisticsModel)
        return <></>

    

    return (
        <>
            <div className="p-3">
                <Row>
                    <Col className='d-flex flex-column justify-content-end'>
                        <strong>{t('statistics_title', { ns: 'promotion_timeline' })} {promotionCriteria.dateRange && getUserReadableDateRange(rangeParse(promotionCriteria.dateRange))}</strong>
                    </Col>
                    <Col sm="auto">
                        <ButtonGroup>
                            <Button onClick={() => setCriteriaToggle(rel => !rel)}><FontAwesomeIcon icon={faFilter} /></Button>
                            <Button color='success' onClick={async () => { await downloadStatisticsExcel(promotionCriteria) }}><FontAwesomeIcon icon={faFileExcel} /></Button>
                        </ButtonGroup>
                    </Col>
                    <Col sm={1} md={1} className='justify-content-end d-flex flex-row'>
                        <Button color='danger' onClick={toggle}><FontAwesomeIcon icon={faX} /></Button>
                    </Col>
                </Row>
            </div>
            <Nav tabs>
                <NavItem>
                    <NavLink active={activeTab == '1'} onClick={() => { setActiveTab('1') }}>
                        {t('beer')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={activeTab == '2'} onClick={() => { setActiveTab('2') }}>
                        {t('cider')}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <StatisticsBeer statisticsModel={statisticsModel} />
                </TabPane>
                <TabPane tabId="2">
                    <StatisticsCider statisticsModel={statisticsModel} />
                </TabPane>
            </TabContent>
            <CriteriaModal
                setSelectedPromotionCriteria={setSelectedPromotionCriteria}
                toggle={() => setCriteriaToggle(rel => !rel)}
                initialFilter={initialFilter}
                isOpen={criteriaToggle}
                currentFilter={promotionCriteria}
            />
        </>
    )
}
