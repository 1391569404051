import { normalizedNumber } from "../../helpers/utils"

export function StatisticsEntry(
        {
            columns
        }: {
            columns: (ColumnEntry | NumberColumnEntry | DeltaColumnEntry | DeltaPerColumnEntry)[]
        }
) {

    return <tr>
        {columns.map(m => <TdColumn column={m}/>)}
    </tr>
}

export function TdColumn(
    {
        column
    }: {
        column: ColumnEntry | NumberColumnEntry | DeltaColumnEntry | DeltaPerColumnEntry 
    }
) {
    switch(column.type) {
        case 'Plain':
            return <td className={column.className ?? ""}>{column.value.toLocaleString()}</td>
        case 'Financial':
            return <td className={column.className ?? ""}>{normalizedNumber(column.value).toLocaleString()}</td>
        case 'Delta':
            {
                let color = "lightgray";
                if (column.value > 0) {
                    color = 'green';
                } else if (column.value < 0) {
                    color = 'red';
                }
                return <td className={column.className ?? ""} style={{ color: color }}>{normalizedNumber(column.value).toLocaleString()}</td>
            }
        default:
            {
                let color = "lightgray";
                if (column.value > 0) {
                    color = 'green';
                } else if (column.value< 0) {
                    color = 'red';
                }
                return <td className={column.className ?? ""} style={{ color: color }}>{normalizedNumber(column.value).toLocaleString()}%</td>
            }
    }

}

export interface ColumnEntry {
    type: 'Plain'
    value: any
    className ?: string
}

export interface NumberColumnEntry {
    type: 'Financial'
    value: number
    className ?: string
}

export interface DeltaColumnEntry {
    type: 'Delta'
    value: number
    className ?: string
}

export interface DeltaPerColumnEntry {
    type: 'Delta %'
    value: number
    className ?: string
}