import './Statistics.css'
import { Table } from "reactstrap"
import { StatisticsEntry } from "./StatisticsEntry";
import { useTranslation } from 'react-i18next';
import { statisticsModel } from '../../backoffice/dtos/statistics/statisticsModel';


export default function StatisticsCider(
    {
        statisticsModel
    }: {
        statisticsModel: statisticsModel
    }
) {
    const { t } = useTranslation(['statistics', 'promotion_timeline'])
    if (!statisticsModel)
        return <></>

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th className="text-align-center">Top 5</th>
                    </tr>
                </thead>
            </Table>
            <Table striped hover>
                <thead>
                    <tr>
                        <th className="text-align-center">{t('brand')}</th>
                        <th className="text-align-center">{t('cider')} (Hls)</th>
                        <th className="text-align-center">LY {t('cider')} (Hls)</th>
                        <th className="text-align-center">{t('cider')} (Hls) Diff</th>
                        <th className="text-align-center">{t('cider')} (Hls) Diff %</th>
                        <th className="text-align-center">{t('cider')} (€)</th>
                        <th className="text-align-center">LY {t('cider')} (€)</th>
                        <th className="text-align-center">{t('cider')} (€) Diff</th>
                        <th className="text-align-center">{t('cider')} (€) Diff %</th>
                    </tr>
                </thead>
                <tbody>
                    {statisticsModel.topCustomersDetails.map(m =>
                        <StatisticsEntry columns={[
                            { type: 'Plain', value: m.company },
                            { type: 'Financial', value: (m.ciderHls), className: "text-align-end" },
                            { type: 'Financial', value: (m.lyCiderHls), className: "text-align-end" },
                            { type: 'Delta', value: (m.ciderHlsDelta), className: "text-align-end" },
                            { type: 'Delta %', value: (m.ciderHlsDeltaPer), className: "text-align-end" },
                            { type: 'Financial', value: (m.ciderEuro), className: "text-align-end" },
                            { type: 'Financial', value: (m.lyCiderEuro), className: "text-align-end" },
                            { type: 'Delta', value: (m.ciderEuroDelta), className: "text-align-end" },
                            { type: 'Delta %', value: (m.ciderEuroDeltaPer), className: "text-align-end" },
                        ]} />)}
                    <StatisticsEntry columns={[
                        { type: 'Plain', value: t('total'), className: "strong" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.ciderHls), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.lyCiderHls), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.topCustomers.ciderHlsDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.topCustomers.ciderHlsDeltaPer), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.ciderEuro), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.lyCiderEuro), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.topCustomers.ciderEuroDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.topCustomers.ciderEuroDeltaPer), className: "text-align-end" },
                    ]} />
                </tbody>
            </Table>

            <br />
            <Table>
                <thead>
                    <tr>
                        <th className="text-align-center">Other</th>
                    </tr>
                </thead>
            </Table>
            <Table striped hover>
                <thead>
                    <tr>
                        <th className="text-align-center">{t('brand')}</th>
                        <th className="text-align-center">{t('cider')} (Hls)</th>
                        <th className="text-align-center">LY {t('cider')} (Hls)</th>
                        <th className="text-align-center">{t('cider')} (Hls) Diff</th>
                        <th className="text-align-center">{t('cider')} (Hls) Diff %</th>
                        <th className="text-align-center">{t('cider')} (€)</th>
                        <th className="text-align-center">LY {t('cider')} (€)</th>
                        <th className="text-align-center">{t('cider')} (€) Diff</th>
                        <th className="text-align-center">{t('cider')} (€) Diff %</th>
                    </tr>
                </thead>
                <tbody>
                    {statisticsModel.otherCustomersDetails.map(m =>
                        <StatisticsEntry columns={[
                            { type: 'Plain', value: m.company },
                            { type: 'Financial', value: (m.ciderHls), className: "text-align-end" },
                            { type: 'Financial', value: (m.lyCiderHls), className: "text-align-end" },
                            { type: 'Delta', value: (m.ciderHlsDelta), className: "text-align-end" },
                            { type: 'Delta %', value: (m.ciderHlsDeltaPer), className: "text-align-end" },
                            { type: 'Financial', value: (m.ciderEuro), className: "text-align-end" },
                            { type: 'Financial', value: (m.lyCiderEuro), className: "text-align-end" },
                            { type: 'Delta', value: (m.ciderEuroDelta), className: "text-align-end" },
                            { type: 'Delta %', value: (m.ciderEuroDeltaPer), className: "text-align-end" },
                        ]} />)}
                    <StatisticsEntry columns={[
                        { type: 'Plain', value: t('total'), className: "strong" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.ciderHls), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.lyCiderHls), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.otherCustomers.ciderHlsDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.otherCustomers.ciderHlsDeltaPer), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.ciderEuro), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.lyCiderEuro), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.otherCustomers.ciderEuroDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.otherCustomers.ciderEuroDeltaPer), className: "text-align-end" },
                    ]} />
                </tbody>
            </Table>
            <br />
            <Table>
                <thead>
                    <tr>
                        <th className="text-align-center">Totals</th>
                    </tr>
                </thead>
            </Table>
            <Table striped hover>
                <thead>
                    <tr>
                        <th className="text-align-center">{t('brand')}</th>
                        <th className="text-align-center">{t('cider')} (Hls)</th>
                        <th className="text-align-center">LY {t('cider')} (Hls)</th>
                        <th className="text-align-center">{t('cider')} (Hls) Diff</th>
                        <th className="text-align-center">{t('cider')} (Hls) Diff %</th>
                        <th className="text-align-center">{t('cider')} (€)</th>
                        <th className="text-align-center">LY {t('cider')} (€)</th>
                        <th className="text-align-center">{t('cider')} (€) Diff</th>
                        <th className="text-align-center">{t('cider')} (€) Diff %</th>
                    </tr>
                </thead>
                <tbody>
                    <StatisticsEntry columns={[
                        { type: 'Plain', value: `TOP 5 ${t('total')}`, className: "strong" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.ciderHls), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.lyCiderHls), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.topCustomers.ciderHlsDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.topCustomers.ciderHlsDeltaPer), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.ciderEuro), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.topCustomers.lyCiderEuro), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.topCustomers.ciderEuroDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.topCustomers.ciderEuroDeltaPer), className: "text-align-end" },
                    ]} />
                    <StatisticsEntry columns={[
                        { type: 'Plain', value: `Other ${t('total')}`, className: "strong" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.ciderHls), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.lyCiderHls), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.otherCustomers.ciderHlsDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.otherCustomers.ciderHlsDeltaPer), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.ciderEuro), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.otherCustomers.lyCiderEuro), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.otherCustomers.ciderEuroDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.otherCustomers.ciderEuroDeltaPer), className: "text-align-end" },
                    ]} />
                    <StatisticsEntry columns={[
                        { type: 'Plain', value: t('total'), className: "strong" },
                        { type: 'Financial', value: (statisticsModel.allCustomers.ciderHls), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.allCustomers.lyCiderHls), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.allCustomers.ciderHlsDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.allCustomers.ciderHlsDeltaPer), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.allCustomers.ciderEuro), className: "text-align-end" },
                        { type: 'Financial', value: (statisticsModel.allCustomers.lyCiderEuro), className: "text-align-end" },
                        { type: 'Delta', value: (statisticsModel.allCustomers.ciderEuroDelta), className: "text-align-end" },
                        { type: 'Delta %', value: (statisticsModel.allCustomers.ciderEuroDeltaPer), className: "text-align-end" },
                    ]} />
                </tbody>
            </Table>
        </>
    )
}
