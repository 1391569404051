import axios, { AxiosRequestConfig } from "axios";
import { clearLocalStorage, getUser } from "../../helpers/localStorage";
import { PromotionCriteria, promotionCriteriaToQuery } from "../dtos/product/promotionCriteria";
import { handleUrl } from "../../helpers/utils";
import { ChangePasswordDto,ResetPasswordDto,ResetPasswordRequestDto } from "../dtos/authentication/ChangePasswordDto";
import { requestMutex } from "./baseAPI";
import { AxiosError } from "axios";


const API = axios.create({baseURL: process.env.REACT_APP_BASE_URL, withCredentials:true})

API.interceptors.response.use(undefined, async (error: AxiosError) => {
    const response = error.response;

    if (response == null || response.status !== 401 || response.config.url?.includes("Authentication/Renew"))
      return Promise.reject(error)
  
    if(requestMutex.isLocked()){
      await requestMutex.waitForUnlock();
      if (getUser())
        return await API.request(response.config)
    } else {
      const lease = await requestMutex.acquire();
  
      try {
        const refreshResult = await renew()
        // Retry the initial query
        console.log(refreshResult)
        return await API.request(response.config);
      } catch(error) {
        clearLocalStorage()
      } finally {
        // release must be called once the mutex should be released again.
        lease();
      }
    }
  
    return Promise.reject(error);    
})

// API.interceptors.request.use((req: any) => {
//     const user = getUser();
//     if (user)
//             req.headers.Authorization = `Bearer ${user?.jwt}`;
//     return req;
// });

export async function unwrapError(error: any): Promise<string> {
    const messagePromise = error.response?.data?.text()
    let message: string | undefined = undefined;
    if (messagePromise) {
            message = await messagePromise;
    }
    if (!message || message.trim().length === 0) {
        message = error.message;
    }
    if (!message || message.trim().length === 0) {
        message = "Unknown Error";
    }
    return message!!;
}

export function unwrapDataError(error: any): string {
    const messagePromise = error.response?.data
    
    return error.response?.data as string ?? error.message ?? "Unknown Error";
}

export async function unwrapObjectError<T>(error: any): Promise<T | null> {
    return error.response?.data ?? null;
}

export const excelApi = (promotionCriteria: PromotionCriteria) => 
    API.get(handleUrl("Promotions/GenerateExcelFile", promotionCriteriaToQuery(promotionCriteria)), { responseType: 'blob' });

export const statisticsExcelApi = (promotionCriteria: PromotionCriteria) => 
    API.get(handleUrl("Statistics/GetStatisticsExcel", promotionCriteriaToQuery(promotionCriteria)), { responseType: 'blob' });

export const sendChangePassword = (changePassword: ChangePasswordDto) =>
    API.patch("User/ChangePassword", changePassword)

export const resetPasswordRequest = (resetPasswordRequestDto: ResetPasswordRequestDto ) => 
    API.post("User/Reset/Password/Request", resetPasswordRequestDto)

export const resetPassword = (resetPasswordDto: ResetPasswordDto) =>
    API.post("User/Reset/Password", resetPasswordDto)

export const tokenExist = (token:string) =>
    API.get(`User/Reset/Password/${token}`)

export const changeLocale = (locale:string) =>
    API.get(`Localizer?locale=${locale}`)

export const logout = () => 
    API.post('Authentication/User/Logout')

export const fetchUser = (config?: AxiosRequestConfig<any> | undefined) =>
    API.get('Authentication/GetUser', config)

export const renew = (config?: AxiosRequestConfig<any> | undefined) =>
    API.get('Authentication/User/Renew', config)
