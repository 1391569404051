import { toast } from "react-toastify";
import { download } from "../../helpers/utils";
import { PromotionCriteria } from "../dtos/product/promotionCriteria";
import { excelApi, statisticsExcelApi, unwrapError } from "./axios";

export async function downloadExcel(promotionCriteria: PromotionCriteria) {
    try {
        const { data } = await excelApi(promotionCriteria);
        download(new Blob([data]), "promotions_report.xlsx");
    } catch (error: any) {
        toast(
            await unwrapError(error));
    }
}

export async function downloadStatisticsExcel(promotionCriteria: PromotionCriteria) {
    try {
        const { data } = await statisticsExcelApi(promotionCriteria);
        download(new Blob([data]), "statistics_report.xlsx");
    } catch (error: any) {
        toast(
            await unwrapError(error));
    }
}